import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Colors from "../../../assets/styles/Colors";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import Loader from "../../../components/Loader";
import { UserRole } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { Container } from "../../../assets/styles/GlobalStyle";
//
// If there is a token, then log in automatically
const AutoLogin = ({ children }: any) => {
  const auth = useAppSelector((data) => data.auth);
  if (auth.userloading) {
    return <Loader />;
  }
  return auth.token && auth.user && auth.user.userType !== "User" ? (
    <Navigate
      replace
      to={"dashboard"}
      state={{ from: "routerProps.location" }}
    />
  ) : (
    children
  );
};
//
const Admin_login = () => {
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const [login, { isLoading, data: isLoginData }] = api.useLoginMutation();
  //
  const [logout, { isLoading: logOutLoading }] = api.useLogoutMutation();
  //
  const [form] = Form.useForm();
  //
  const LogoutFunction = async () => {
    try {
      await logout({}).unwrap();
    } catch (error) {
      toast("logout error | " + error);
    }
  };
  //
  // if user don't have access in admin
  useEffect(() => {
    if (
      (isLoginData && isLoginData.user.userType === UserRole["User"]) ||
      (isLoginData && isLoginData.user.activated === false)
    ) {
      //
      LogoutFunction();
      //
      if (isLoginData.user.userType === UserRole["User"]) {
        toast.info(`${t("notAccessAdmin")}`);
      }
      //
      if (isLoginData.user.activated === false) {
        toast.info(`${t("accountDeactivated")}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginData]);
  //
  //
  const onFinish = async (values: any) => {
    try {
      await login({ email: values.email, password: values.password }).unwrap();
      navigate(`dashboard`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  //
  const MainLoading = isLoading || logOutLoading;
  //
  //
  return MainLoading ? (
    <Loader />
  ) : (
    <AutoLogin>
      <Wrapper>
        <Container>
          <Grid>
            <Block>
              <Form
                form={form}
                name="horizontal_login"
                layout="vertical"
                onFinish={onFinish}
                autoFocus={true}
              >
                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="email"
                  rules={[{ required: true }]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder={t("email")}
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                  }}
                  name="password"
                  rules={[{ required: true }]}
                >
                  <Input.Password
                    autoComplete="on"
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder={t("password")}
                  />
                </Form.Item>

                <Form.Item
                  shouldUpdate
                  style={{
                    marginBlock: 0,
                  }}
                >
                  {() => (
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="primary"
                      htmlType="submit"
                      size="large"
                      disabled={
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      {t("signIn")}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Block>
          </Grid>
        </Container>
      </Wrapper>
    </AutoLogin>
  );
};

const Wrapper = styled.div`
  background: linear-gradient(
    -26.3deg,
    ${Colors.primaryRed} 50%,
    ${Colors.grey} 50%
  );
`;
const Grid = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`;
const Block = styled.div`
  background-color: ${Colors.white};
  padding: 70px 56px;
  border-radius: 8px;
  max-width: 528px;
  width: 100%;

  ${Responsive.mobile} {
    padding: 30px;
  }
`;

export default Admin_login;
