import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Spin,
  Tooltip,
} from "antd";
import Meta from "antd/es/card/Meta";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import UploadFile from "../../../components/UploadFile";
import { AddTravelRisk, EnabledLanguage } from "../../../domain/types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { AppState, setEditedlanguage } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
//
//
type AddTravelRiskTranslatedFields = {
  riskName: string;
  icon: string;
};
//
//
const TravelRisk = () => {
  const dispatch = useAppDispatch();

  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const { lang } = useAppSelector((root) => root.app);

  const { t } = useTranslation();

  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);

  const onCloseDrawer = () => {
    setDrawerStatus(false);
  };

  // selected risk id
  const [travelRiskId, setTravelRiskId] = useState<number | null>(null);

  // add travel risk state
  const [addTravelRiskState, setAddTravelRiskState] = useState<
    Partial<AddTravelRisk>
  >({});
  //
  // get all travel risks list
  const {
    data: travelRisks,
    isLoading: isTravelRisksLoading,
    refetch: refetchTravelRisks,
  } = api.useGetAdminTravelRiskListQuery(undefined);
  //
  //
  // add travel risk
  const [saveTravelRisk, { isLoading: isAddTravelRiskLoading }] =
    api.useSaveTravelRiskMutation();
  //
  // remvoe travel risk
  const [
    removeTravelRisk,
    { isLoading: isRemoveTravelRiskLoading, isSuccess: removeTravelRiskSucces },
  ] = api.useRemoveTravelRiskMutation();
  //
  //

  //
  // add travel risk fields
  const setAddTravelRisk = (field: keyof AddTravelRisk, value: any) => {
    setAddTravelRiskState({
      ...addTravelRiskState,
      [field]: value,
    });
  };
  //
  //
  const { data: existingTravelRisk, isLoading: isExistingTravelRiskLoading } =
    api.useGetAdminTravelRiskByIdQuery(travelRiskId || 0);

  // add travel translation fields
  const setAddTravelRiskTranslatedField = (
    field: keyof AddTravelRiskTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddTravelRiskState({
      ...addTravelRiskState,
      translations: {
        ...addTravelRiskState.translations,
        [language]: {
          ...addTravelRiskState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add travel risk function
  const AddTravelRiskFunction = async () => {
    //
    try {
      await saveTravelRisk(addTravelRiskState).unwrap();
      // refetch travel risks
      refetchTravelRisks();
      // close modal
      _onAddTravelRiskCloseDrawer();
      //
      if (
        existingTravelRisk &&
        existingTravelRisk.id === addTravelRiskState.id
      ) {
        toast.success(`${t("update")}`);
      } else {
        toast.success(`${t("create")}`);
      }
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // auto refetch travel risks when user remove risk
  useEffect(() => {
    if (removeTravelRiskSucces) {
      refetchTravelRisks();
      _onAddTravelRiskCloseDrawer();
    }
  }, [removeTravelRisk, removeTravelRiskSucces, refetchTravelRisks]);

  useEffect(() => {
    if (existingTravelRisk) {
      setAddTravelRiskState(existingTravelRisk);
    }
  }, [existingTravelRisk]);

  //close add trave risk drawer
  const _onAddTravelRiskCloseDrawer = () => {
    return [
      setTravelRiskId(null),
      setDrawerStatus(false),
      setAddTravelRiskState({}),
    ];
  };
  //
  const drawerLoading =
    isExistingTravelRiskLoading ||
    isAddTravelRiskLoading ||
    isRemoveTravelRiskLoading;
  //
  return (
    <Wrapper>
      <Button
        style={{
          marginBottom: "20px",
        }}
        size="large"
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => [
          setDrawerStatus(!drawerStatus),
          setAddTravelRiskState({}),
          setTravelRiskId(null),
          {},
        ]}
      >
        {t("createRisk")}
      </Button>

      <RisksContent>
        {isTravelRisksLoading ? (
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        ) : (
          <>
            {travelRisks &&
              travelRisks.map((item, index) => (
                <Card
                  key={index}
                  onClick={() => [
                    setTravelRiskId(item.id),
                    setDrawerStatus(!drawerStatus),
                  ]}
                  hoverable
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  cover={
                    <img
                      alt="example"
                      style={{
                        width: "50px",
                      }}
                      src={item.icon}
                    />
                  }
                >
                  <Meta title={item.translations[lang]?.riskName} />
                </Card>
              ))}
          </>
        )}
      </RisksContent>

      <Drawer
        extra={
          <AdminLangSwicher
            onChange={(value) => dispatch(setEditedlanguage(value))}
          />
        }
        title={travelRiskId ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        {drawerLoading ? (
          <Flex justify="center" align="center">
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </Flex>
        ) : (
          <Flex vertical gap={20}>
            <Input
              size="large"
              placeholder={t("riskTitle")}
              value={
                addTravelRiskState?.translations?.[field_Language]?.riskName ||
                ""
              }
              onChange={(event) =>
                setAddTravelRiskTranslatedField(
                  "riskName",
                  field_Language,
                  event?.target?.value
                )
              }
              suffix={
                <Tooltip title={t("riskTitle")}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />

            <UploadFile
              label={t("riskIcon")}
              onChange={(props) => setAddTravelRisk("icon", props.url)}
              defaultFileName={addTravelRiskState?.icon || ""}
            />

            <Divider />

            <Flex align="center" justify="space-between" gap={20}>
              <Button
                size="large"
                onClick={AddTravelRiskFunction}
                disabled={drawerLoading}
                style={{
                  width: "100%",
                }}
              >
                {travelRiskId ? t("update") : t("create")}
              </Button>

              {travelRiskId && (
                <Popconfirm
                  disabled={drawerLoading}
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeTravelRisk(travelRiskId)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button
                    disabled={drawerLoading}
                    danger
                    size="large"
                    style={{
                      width: "100%",
                    }}
                  >
                    {t("removeRisk")}
                  </Button>
                </Popconfirm>
              )}
            </Flex>
          </Flex>
        )}
      </Drawer>
    </Wrapper>
  );
};
//
const RisksContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;
const Wrapper = styled.div``;
//
export default TravelRisk;
