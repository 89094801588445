import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../api";
import Responsive from "../assets/config/Responsive";
import CloseIcon from "../assets/icons/JSX/CloseIcon";
import UploadFileIcon from "../assets/icons/JSX/UploadFileIcon";
import Colors from "../assets/styles/Colors";

const UploadFile: React.FC<{
  label?: string;
  btnText?: string;
  errorMessage?: string;
  defaultFileName?: string;
  disabled?: boolean;
  onChange: (props: { url: string; key: string }) => void;
  style?: React.CSSProperties;
}> = ({
  label = "please enter label text",
  btnText,
  errorMessage,
  defaultFileName,
  disabled = false,
  onChange,
  style,
}) => {
  //
  const { t } = useTranslation();
  //  upload file & image
  const [uploadFileMutation, { isLoading: isUploadFileLoading }] =
    api.useUploadFileMutation();
  //
  const [fileName, setFileName] = useState<string | undefined>(defaultFileName);
  //
  const fileOnchange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    //
    if (!fileObj) {
      return;
    }
    //
    const file = event?.target?.files?.[0];
    //
    if (!file) {
      toast.error(`${t("pleaseChooseFile")}`);
      return;
    }
    //
    const result = await uploadFileMutation({ file }).unwrap();
    //
    onChange({ url: result?.url, key: result?.key });
    // reset file input
    event.target.value = null;
  };
  //
  useEffect(() => {
    setFileName(defaultFileName);
  }, [defaultFileName]);

  const fileNameFromUrl = fileName
    ?.split("/images/")[1]
    ?.split("-")
    .slice(1)
    .join("-");

  //
  return (
    <Wrapper style={style} $disabled={disabled}>
      <Box>
        <Input type="file" onChange={fileOnchange} />

        <UploadBox $selected={fileName ? true : false}>
          {!fileName && isUploadFileLoading && <div>{t("loading")}</div>}

          {fileName && (
            <UploadedFile>
              <span>{fileNameFromUrl}</span>
              <CloseIcon onClick={() => setFileName(undefined)} />
            </UploadedFile>
          )}

          {!fileName && !isUploadFileLoading && (
            <>
              <UploadFileIcon />
              <span>{btnText ? btnText : t("uploadFile")}</span>
            </>
          )}
        </UploadBox>
      </Box>
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </Wrapper>
  );
};
//
//
// styles
const ErrorMessage = styled.div`
  font-size: 14px;
  color: ${Colors.primaryRed};
  margin-top: 10px;
`;
const UploadedFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 20px;

  svg {
    width: 13px !important;
    cursor: pointer;
    z-index: 3;
    position: absolute;
    right: 10px;
  }
  span {
    pointer-events: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const UploadBox = styled.div<{ $selected: boolean }>`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  pointer-events: ${({ $selected }) => ($selected === true ? "all" : "none")};
  border: 1px solid ${Colors.primaryBlack};

  /* span */
  span {
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.primaryBlack};
    pointer-events: none;
  }

  /* icon svg */
  svg {
    margin-right: 10px;
    color: ${Colors.primaryBlack};
    height: 16px;
    width: fit-content;
    background-color: ${Colors.white};
  }
`;
const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: ${Colors.white};
  -webkit-box-shadow: 0px 0px 15px 3px #88888811;
  box-shadow: 0px 0px 15px 3px #88888811;
  border-radius: 8px;
`;

const Input = styled.input`
  height: 40px;
  width: 100%;
  background-color: ${Colors.white};
  border-radius: 8px;
  z-index: 1;

  ${Responsive.mobile} {
    height: 48px;
  }
`;
const Wrapper = styled.div<{ $disabled: boolean }>`
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  user-select: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  opacity: ${({ $disabled }) => ($disabled ? "0.7" : "unset")};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default UploadFile;
